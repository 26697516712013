var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-user-profile"},[_c('b-container',{staticClass:"mt-3"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"h1"},[_vm._v(_vm._s(_vm.$t('profile_page_name_title')))])],1),_c('b-form-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('profile_page_name_label'),"label-for":"input-name","disabled":""}},[_c('b-form-input',{ref:"name",attrs:{"id":"input-name","disabled":"","trim":"","autocomplete":"username"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1)],1),(_vm.changePassword)?_c('b-form-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('profile_page_current_password_label'),"label-for":"input-current-password","invalid-feedback":_vm.currentPasswordError,"state":_vm.currentPasswordIsValid && !this.currentPasswordServerError}},[_c('b-form-input',{attrs:{"id":"input-current-password","type":"password","state":_vm.currentPasswordIsValid && !this.currentPasswordServerError,"autocomplete":"current-password","trim":"","autofocus":""},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})],1)],1)],1):_vm._e(),(_vm.changePassword)?_c('b-form-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('profile_page_new_password_label'),"label-for":"input-new-password","state":_vm.newPasswordIsValid && _vm.newPasswordIsValid.All}},[_c('b-form-input',{attrs:{"id":"input-new-password","type":"password","autocomplete":"new-password","state":_vm.newPasswordIsValid &&
                  _vm.newPasswordIsValid.All &&
                  !_vm.newPasswordServerError,"trim":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),(_vm.newPasswordIsValid)?_vm._t("description",[_c('PasswordValidFeedback',{attrs:{"newPasswordIsValid":_vm.newPasswordIsValid,"passwordPolicy":_vm.passwordPolicy}})]):_vm._e()],2)],1)],1):_vm._e(),(_vm.changePassword)?_c('b-form-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('profile_page_new_password_retype_label'),"label-for":"input-new-password-retype","invalid-feedback":_vm.passwordRetypeError,"state":_vm.passwordRetypeIsValid}},[_c('b-form-input',{attrs:{"id":"input-new-password-retype","type":"password","autocomplete":"new-password","state":_vm.passwordRetypeIsValid && !_vm.passwordRetypeServerError,"trim":""},model:{value:(_vm.newPasswordRetype),callback:function ($$v) {_vm.newPasswordRetype=$$v},expression:"newPasswordRetype"}})],1)],1)],1):_vm._e(),_c('b-form-row',{staticClass:"justify-content-between"},[_c('b-col',[(!_vm.changePassword)?_c('b-button',{attrs:{"variant":"outline-primary","disabled":!_vm.passwordPolicy},on:{"click":function($event){_vm.changePassword = true}}},[_c('b-overlay',{attrs:{"blur":"1px","spinner-small":"","show":!_vm.passwordPolicy}},[_vm._v(_vm._s(_vm.$t('profile_page_change_password')))])],1):_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.cancelChangePassword}},[_vm._v(_vm._s(_vm.$t('profile_page_cancel_change_password')))])],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":!(
                _vm.currentPasswordIsValid &&
                _vm.newPasswordIsValid &&
                _vm.newPasswordIsValid.All &&
                _vm.passwordRetypeIsValid
              )},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('common_save')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }