/**
 * User profile page.
 */
import { Auth } from 'aws-amplify';
import { apiGet } from '@/util/api';
import { passwordIsValid } from '@/util/auth';

import PasswordValidFeedback from '@/components/PasswordValidFeedback.vue';

export default {
  name: 'Profile',
  components: {
    PasswordValidFeedback,
  },
  data: () => ({
    name: '',
    changePassword: false,
    currentPassword: '',
    newPassword: '',
    newPasswordRetype: '',
    passwordPolicy: null,

    // nameServerError: false,
    // nameError: '',
    currentPasswordServerError: false,
    currentPasswordError: '',
    newPasswordServerError: false,
    passwordRetypeServerError: false,
    passwordRetypeError: '',
  }),
  computed: {
    // nameIsValid: function() {
    //   const trimmedName = this.name.trim();
    //   const valid = !!trimmedName && !this.nameBlank;
    //
    //   if (valid) {
    //     this.nameError = '';
    //   } else {
    //     this.nameError = 'Name cannot be blank.';
    //   }
    //
    //   return valid;
    // },
    currentPasswordIsValid: function() {
      if (!this.currentPassword) return;
      const valid = this.currentPassword && this.currentPassword.length > 5;

      if (valid) {
        this.currentPasswordError = '';
      } else {
        this.currentPasswordError = this.$t(
          'profile_page_validation_error_current_password_min_6',
        );
      }

      return valid;
    },
    newPasswordIsValid: function() {
      return passwordIsValid(this.passwordPolicy, this.newPassword);
    },
    passwordRetypeIsValid() {
      if (!this.newPasswordIsValid && this.newPasswordRetype) return false;
      if (!this.newPasswordRetype) return;

      const valid =
        this.newPasswordIsValid.All &&
        this.newPassword === this.newPasswordRetype;

      if (valid) {
        this.passwordRetypeError = '';
      } else {
        this.passwordRetypeError = this.$t(
          'profile_page_validation_error_password_retype_doesnt_match',
        );
      }

      return valid;
    },
  },
  async created() {
    this.name = this.$store.state.user.name;
    this.passwordPolicy = await apiGet('/user/password-policy');
  },
  methods: {
    async save() {
      // Change user details - not currently supported
      // try {
      //   await apiPut('/user/me', {
      //     name: this.name,
      //     currentPassword: this.currentPassword,
      //     newPassword: this.newPassword,
      //   });
      // await this.$store.commit('setUser', updatedUser);

      // Change password
      const currentUser = await Auth.currentAuthenticatedUser().catch(() => {});
      await Auth.changePassword(
        currentUser,
        this.currentPassword,
        this.newPassword,
      )
        .then(() => {
          this.$bvToast.toast(this.$t('profile_page_success_save'), {
            title: this.$t('common_success_title'),
            variant: 'success',
          });
          this.reset();
        })
        .catch(error => {
          let errorCode = 'internal_error';
          if (error.code) {
            errorCode = error.code;
          }

          switch (errorCode) {
            case 'NotAuthorizedException':
            case 'LimitExceededException':
              this.currentPasswordServerError = true;
              break;
            case 'InvalidParameterException':
              this.newPasswordServerError = true;
              this.passwordRetypeServerError = true;
          }

          this.$bvToast.toast(
            this.$t(
              errorCode === 'internal_error'
                ? 'common_error_body'
                : `profile_page_error_save_${errorCode}`,
            ),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        });
    },
    reset() {
      this.name = this.$store.state.user.name;
      this.cancelChangePassword();
    },
    cancelChangePassword() {
      this.changePassword = false;
      this.currentPassword = '';
      this.newPassword = '';
      this.newPasswordRetype = '';
      this.$refs['name'].focus();
    },
  },
};
